import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useCasesData } from "./UseCasesSub";

import EmailForm from "../components/Form";
//import Reviews from "../components/reviews";
import Clicker from "../components/clicker";

import HomeStyles from "./Home.module.scss";
//import { ping } from "../lib/api";

import docker from "../assets/svg/docker.svg";
import unicage from "../assets/svg/unicage.svg";

import feature1 from "../assets/svg/feature1.svg";
import feature2 from "../assets/svg/feature2.svg";
import feature3 from "../assets/svg/feature3.svg";
import feature4 from "../assets/svg/feature4.svg";

const { HomeSection1, HomeSection2, HomeSection3 } = HomeStyles;

const Home = () => {
  const { t } = useTranslation("common");
  const features = [feature1, feature2, feature3, feature4];
  const navigate = useNavigate();
  const handleTryUpx = () => navigate("/#try-upx");
  const handleUseCaseSeeMore = () => navigate("/use-cases");

  const handleOnePager = () => window.open("/PDF/UPX Introduction.pdf");
  const handleNavigate = (path) => navigate(`/use-cases/${path}`);

  return (
    <div>
      <div className={HomeSection1}>
        <div>
          <h1>{t("home.section1.title1")}</h1>
          <h1>{t("home.section1.title2")}</h1>
          <p>{t("home.section1.paragraph")}</p>
          <div>
            <Button onClick={handleTryUpx}>{t("shared.try_upx")}</Button>
            <Button onClick={handleOnePager}>
              {t("home.section1.one_pager")}
            </Button>
          </div>
        </div>
        <Clicker />
      </div>
      <div className={HomeSection2}>
        <h1>
          {t("home.section2.title")}{" "}
          <span>{t("home.section2.title_highlighted")}</span>
        </h1>
        <p>{t("home.section2.paragraph")}</p>
        {features.map((feature, key) => (
          <div key={key}>
            <img
              alt={t(`home.section2.feature${key + 1}.title`)}
              src={feature}
            />
            <div>
              <h1>
                {t(`home.section2.feature${key + 1}.title`)}{" "}
                <span>
                  {t(`home.section2.feature${key + 1}.title_highlighted`)}
                </span>
              </h1>
              <p>{t(`home.section2.feature${key + 1}.paragraph`)}</p>
              <div>
                <img alt="docker" src={docker} />
                <img alt="unicage" src={unicage} />
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className={HomeSection3}>
        <h1>{t("home.section3.title")}</h1>
        <p>{t("home.section3.paragraph")}</p>
        <div>
          {Object.keys(useCasesData)
            .slice(0, 6)
            .map((e, key) => (
              <div key={key}>
                <div onClick={() => handleNavigate(e)}>
                  <img alt={e} src={useCasesData[e].case} />
                  <p>{t(`home.section3.case${key + 1}`)}</p>
                </div>
              </div>
            ))}
        </div>
        <Button onClick={handleUseCaseSeeMore}>{t("shared.see_more")}</Button>
      </div>
      {/* 
      <div className={HomeSection4}>
        <h1>
          <span>{t("home.section4.title_highlighted")}</span>{" "}
          {t("home.section4.title")}
        </h1>
        <p>{t("home.section4.paragraph")}</p>
        <Reviews />
        <ReviewsNew/>
      </div>
      */}
      <EmailForm />
    </div>
  );
};

export default Home;
