import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import EmailForm from "../components/Form";

import { useCasesData } from "./UseCasesSub";
import UseCasesStyles from "./UseCases.module.scss";

const { useCasesContainer } = UseCasesStyles;

export default function UseCaseMain() {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const handleNavigate = (path) => navigate(`/use-cases/${path}`);
  return (
    <>
      <div className={useCasesContainer}>
        <h1>{t("home.section3.title")}</h1>
        <p>{t("home.section3.paragraph")}</p>
        <div>
          {Object.keys(useCasesData).map((e, key) => (
            <div key={key}>
              <div onClick={() => handleNavigate(e)}>
                <img alt={e} src={useCasesData[e].case} />
                <p>{t(`home.section3.case${key + 1}`)}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <EmailForm />
    </>
  );
}
