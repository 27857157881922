import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import error404PageStyles from "./Error404.module.scss";

import _404 from "../assets/img/404.webp";

const { error404PageContainer } = error404PageStyles;

export default function Error404() {
  const navigate = useNavigate();
  const handleBack2Home = () => navigate("/");
  return (
    <div className={error404PageContainer}>
      <div>
        <img alt="404-error" src={_404} />
        <p>There is no content!</p>
        <Button onClick={handleBack2Home}>Back to Home</Button>
      </div>
    </div>
  );
}
