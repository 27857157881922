import { useTranslation } from "react-i18next";
import AboutStyles from "./Company.module.scss";
import Marquee from "react-fast-marquee";

import MarqueeItem from "../components/MarqueeItem";


import partner1 from "../assets/img/partners/1.png";
import partner2 from "../assets/img/partners/2.png";
import partner3 from "../assets/img/partners/3.png";
import partner4 from "../assets/img/partners/4.png";
import partner5 from "../assets/img/partners/5.png";
import partner6 from "../assets/img/partners/6.png";
import partner7 from "../assets/img/partners/7.png";
import partner8 from "../assets/img/partners/8.png";
import partner9 from "../assets/img/partners/9.png";
import partner10 from "../assets/img/partners/10.png";
import partner11 from "../assets/img/partners/11.png";
import partner12 from "../assets/img/partners/12.png";
import partner13 from "../assets/img/partners/13.png";
import partner14 from "../assets/img/partners/14.png";
import partner15 from "../assets/img/partners/15.png";
import partner16 from "../assets/img/partners/16.png";
import partner17 from "../assets/img/partners/17.png";
import partner18 from "../assets/img/partners/18.png";
import partner19 from "../assets/img/partners/19.png";
import partner20 from "../assets/img/partners/20.png";
import partner21 from "../assets/img/partners/21.png";
import partner22 from "../assets/img/partners/22.png";
import partner23 from "../assets/img/partners/23.png";
import partner24 from "../assets/img/partners/24.png";
import partner25 from "../assets/img/partners/25.png";
import partner26 from "../assets/img/partners/26.png";
import partner27 from "../assets/img/partners/27.png";
import partner28 from "../assets/img/partners/28.png";
import partner29 from "../assets/img/partners/29.png";
import partner30 from "../assets/img/partners/30.png";
import partner31 from "../assets/img/partners/31.png";
import partner32 from "../assets/img/partners/32.png";
import partner33 from "../assets/img/partners/33.png";
import partner34 from "../assets/img/partners/34.png";
import partner35 from "../assets/img/partners/35.png";
import partner36 from "../assets/img/partners/36.png";
import partner37 from "../assets/img/partners/37.png";
import partner38 from "../assets/img/partners/38.png";
import EmailForm from "../components/Form";

const {
  AboutSection1,
  AboutSection2,
  AboutSection5,
  marquee,
} = AboutStyles;

const partners = [
  partner1,
  partner2,
  partner3,
  partner4,
  partner5,
  partner6,
  partner7,
  partner8,
  partner9,
  partner10,
  partner11,
  partner12,
  partner13,
  partner14,
  partner15,
  partner16,
  partner17,
  partner18,
  partner19,
  partner20,
  partner21,
  partner22,
  partner23,
  partner24,
  partner25,
  partner26,
  partner27,
  partner28,
  partner29,
  partner30,
  partner31,
  partner32,
  partner33,
  partner34,
  partner35,
  partner36,
  partner37,
  partner38,
]




const About = () => {
  const { t } = useTranslation("common");
  return (
    <div>
      <div className={AboutSection1}>
        <h1>{t("company.section1.title1")} <span>{t("company.section1.title2")}</span></h1>
        <p>{t("company.section1.para1")}</p>
        <p>{t("company.section1.para2")}</p>
        <p>{t("company.section1.para3")}</p>
        <p>{t("company.section1.para4")}</p>
        <p>{t("company.section1.para5")}</p>
      </div>
      <div className={AboutSection2}>
        <h1>{t("company.section2.title1")} <span>{t("company.section2.title2")}</span></h1>
        <p>{t("company.section2.para1")}</p>
        <p>{t("company.section2.para2")}</p>
        <p>{t("company.section2.para3")}</p>
        <p>{t("company.section2.para4")}</p>
        <p>{t("company.section2.para5")}</p>
      </div>
      <div className={AboutSection5}>
        <h1>
          <span>{t("about.section5.title_highlighted")}</span>{" "}
          {t("about.section5.title")}
        </h1>
        <p>
          <span>
            {t("about.section5.para")}
          </span>
        </p>
        <div>
          <Marquee autoFill={true} className={marquee}>
            {partners.map((partner, key) => (
              <MarqueeItem id={key} key={key} source={partner}/>
            ))}
          </Marquee>
        </div>
      </div>
      <EmailForm/>
    </div>
  );
};

export default About;
