import WhyUpxStyles from "./WhyUpx.module.scss";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import EmailForm from "../components/Form";

import startup1 from "../assets/svg/startup-1.svg";
import startup2 from "../assets/svg/startup-2.svg";
import startup3 from "../assets/svg/startup-3.svg";
import startup4 from "../assets/svg/startup-4.svg";
import startup5 from "../assets/svg/startup-5.svg";
import startup6 from "../assets/svg/startup-6.svg";
import startup7 from "../assets/svg/startup-7.svg";
import startup8 from "../assets/svg/startup-8.svg";
import startup9 from "../assets/svg/startup-9.svg";

import ex1 from "../assets/svg/ex1.svg";
import ex2 from "../assets/svg/ex2.svg";
import ex3 from "../assets/svg/ex3.svg";
import ex4 from "../assets/svg/ex4.svg";
import ex5 from "../assets/svg/ex5.svg";
import ex6 from "../assets/svg/ex6.svg";

const {
  WhyUpx: main,
  titler,
  switch: switcher,
  section1,
  section2,
  section3,
  checked,
  unchecked,
  explanation: explanation1,
  ex2: explanation2,
} = WhyUpxStyles;

const WhyUpx = () => {
  const [selected, setSelected] = useState(true);
  const { t } = useTranslation("common");

  const texts = {
    true: {
      section1: {
        main: t("why_upx.startup.section1.main"),
        sub: t("why_upx.startup.section1.sub"),
        para: t("why_upx.startup.section1.para"),
        explanation: [startup1, startup2, startup3, startup4, startup5].map(
          (src, key) => ({
            src,
            title: t(`why_upx.startup.section1.ex${key + 1}.title`),
            para: t(`why_upx.startup.section1.ex${key + 1}.para`),
          })
        ),
      },
      section2: {
        title: t("why_upx.startup.section2.title"),
        para: t("why_upx.startup.section2.para"),
      },
      section3: {
        title: t("why_upx.startup.section3.title"),
        sub: t("why_upx.startup.section3.sub"),
        explanation: [ex1, ex2, ex4, ex5].map(
          (src, key) => ({
            src,
            title: t(`why_upx.startup.section3.ex${key + 1}.title`),
            para: t(`why_upx.startup.section3.ex${key + 1}.para`),
          })
        ),
      },
    },
    false: {
      section1: {
        main: t("why_upx.corporate.section1.main"),
        sub: t("why_upx.corporate.section1.sub"),
        para: t("why_upx.corporate.section1.para"),
        explanation: [startup6, startup7, startup8, startup9, startup3].map(
          (src, key) => ({
            src,
            title: t(`why_upx.corporate.section1.ex${key + 1}.title`),
            para: t(`why_upx.corporate.section1.ex${key + 1}.para`),
          })
        ),
      },
      section2: {
        title: t("why_upx.corporate.section2.title"),
        sub: t("why_upx.corporate.section2.sub"),
        para: t("why_upx.corporate.section2.para"),
      },
      section3: {
        title: "",
        sub: "",
        explanation: [ex2, ex3, ex6, ex5].map((src, key) => ({
          src,
          title: t(`why_upx.startup.section3.ex${key + 1}.title`),
          para: t(`why_upx.startup.section3.ex${key + 1}.para`),
        })),
      },
    },
  };

  return (
    <div className={main}>
      <div className={titler}>
        <span>{t("why_upx.title")}</span>
        <p>{t("why_upx.para")}</p>
      </div>
      <div className={section1}>
        <label className={switcher}>
          <input
            type="checkbox"
            checked={selected}
            onChange={(e) => setSelected(e.target.checked)}
          />
          <h6>{t("why_upx.startup_name")}</h6>
          <h6>{t("why_upx.corporate_name")}</h6>
          <span className={selected ? checked : unchecked}></span>
        </label>
        <span className="title">
          {texts[selected].section1.main}
          <span className="text-green">Data Intelligence</span>
        </span>

        <span className="title">{texts[selected].section1.sub}</span>
        <p>{texts[selected].section1.para}</p>
        {texts[selected].section1.explanation.map(
          ({ src, title, para }, key) => (
            <div className={explanation1} key={key}>
              <img alt={title} src={src} />
              <span data-title>{title}</span>
              <span data-para>{para}</span>
            </div>
          )
        )}
      </div>
      <div className={section2}>
        <h1>{texts[selected].section2.title}</h1>
        <h2>{texts[selected].section2.sub}</h2>
        <p>{texts[selected].section2.para}</p>
      </div>
      <div className={section3}>
        <h1>{texts[selected].section3.title}</h1>
        <h2 className="text-green">{texts[selected].section3.sub}</h2>
        {texts[selected].section3.explanation.map(
          ({ title, src, sub, para }, key) => (
            <div key={key} className={explanation2}>
              <img alt={title} src={src} />
              <h1>{title}</h1>
              <p>{para}</p>
            </div>
          )
        )}
      </div>
      <EmailForm />
    </div>
  );
};

export default WhyUpx;
