import EmailForm from "../components/Form";
import consultingPageStyles from "./Consulting.module.scss";
import { useTranslation } from "react-i18next";


import consultingSvg from '../assets/svg/consulting.svg';
import co1Svg from '../assets/svg/co1.svg'
import co2Svg from '../assets/svg/co2.svg'
import co3Svg from '../assets/svg/co-3.svg'
import con1Svg from '../assets/svg/con1.svg'
import con2Svg from '../assets/svg/con2.svg'
import con3Svg from '../assets/svg/con3.svg'

const { 
  consulting, 
  ConsultingSection1: section1,
  ConsultingSection2: section2,
  ConsultingSection3: section3,
  ConsultingSection4: section4,
  ConsultingEx1: ex1,
  ConsultingEx2: ex2,
} = consultingPageStyles;
const Consulting = () => {
  const {t} = useTranslation('common')
  return (
    <div className={consulting}>
      <div className={section1}>
        <h2>{t('consulting.section1.green_title')}</h2>
        <h1>{t('consulting.section1.title')}</h1>
        <p>{t('consulting.section1.para')}</p>
        <div>
          <img alt="consulting-img" src={consultingSvg} />
        </div>
      </div>
      <div className={section2}>
        <span className="title">{t('consulting.section2.title1')}</span>
        <span className="title">{t('consulting.section2.title2')}</span>
        <p>{t('consulting.section2.para')}</p>
        <div className={ex1}>
          <img alt="con1" src={co1Svg} />
          <span>{t('consulting.section2.item1.title')}</span>
          <span>{t('consulting.section2.item1.para')}</span>
        </div>
        <div className={ex1}>
          <img alt="con1" src={co2Svg} />
          <span>{t('consulting.section2.item2.title')}</span>
          <span>{t('consulting.section2.item2.para')}</span>
        </div>
        <div className={ex1}>
          <img alt="con1" src={co3Svg} />
          <span>{t('consulting.section2.item3.title')}</span>
          <span>{t('consulting.section2.item3.para')}</span>
        </div>
      </div>
      <div className={section3}>
        <h1>{t('consulting.section3.title1')}</h1>
        <h2>{t('consulting.section3.title2')}</h2>
        <p>{t('consulting.section3.para')}</p>
      </div>
      <div className={section4}>
        <h1>{t('consulting.section4.title1')}</h1>
        <h2>{t('consulting.section4.title2')}</h2>
        <div className={ex2}>
          <img alt="con-1" src={con1Svg} />
          <h1>{t('consulting.section4.item1.title')}</h1>
          <p>{t('consulting.section4.item1.para')}</p>
        </div>
        <div className={ex2}>
          <img alt="con-1" src={con2Svg} />
          <h1>{t('consulting.section4.item2.title')}</h1>
          <p>{t('consulting.section4.item2.para')}</p>
        </div>
        <div className={ex2}>
          <img alt="con-1" src={con3Svg} />
          <h1>{t('consulting.section4.item3.title')}</h1>
          <p>{t('consulting.section4.item3.para')}</p>
        </div>
        <div>
          {/* This div is used for last element of 2 2 grid */}
        </div>
      </div>
      <EmailForm/>
    </div>
  );
};

export default Consulting;
