
import verifyPageStyles from "./Verify.module.scss";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const {
  verifyPageContainer,
} = verifyPageStyles;
export default function Verify() {
  const navigate = useNavigate();
  const handleOnClick = () => navigate('/');
  return (
    <div className={verifyPageContainer}>
      <div>
        <CheckCircleIcon color="success"/>
        <h1>Email Verification</h1>
        <p>Your email was verified.</p>
        <p>We sent password setup link to your email.</p>
        <p>Enjoy UPX!</p>
        <Button onClick={handleOnClick}>Back to Home</Button>
      </div>
    </div>
  );
}
