import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import EmailFormStyles from "./Form.module.scss";
import { Button, TextField, Alert, Snackbar } from "@mui/material";
import { sendEmail } from "../lib/api";

const { container, identifier } = EmailFormStyles;

const EmailForm = () => {
  const [alrt, setAlrt] = useState({
    open: false,
    message: "",
    status: "",
  });
  const { t, i18n } = useTranslation("common");
  const [data, setData] = useState({
    name: {
      value: "",
      error: false,
      helperText: "",
      required: true,
      type: "text",
      label: t("form.name"),
    },
    phone: {
      value: "",
      error: false,
      helperText: "",
      required: true,
      type: "tel",
      label: t("form.phone"),
      //placeholder: "xxx-xxxx-xxxx"
    },
    email: {
      value: "",
      error: false,
      helperText: "",
      required: true,
      type: "email",
      label: t("form.email"),
    },
    company: {
      value: "",
      error: false,
      helperText: "",
      required: true,
      type: "text",
      label: t("form.company"),
    },
    size: {
      value: "",
      error: false,
      helperText: "",
      required: true,
      type: "text",
      label: t("form.size"),
    },
  });
  const [idStyle, setIdStyle] = useState({
    height: 0,
    top: 0,
  });

  const validate = (name, value) => {
    const validation = {
      name: {
        type: /^.*.$/,
        text: "Empty Name",
      },
      email: {
        type: /^.*@.*\..*[a-z0-9]$/,
        text: "Invalid Email",
      },
      phone: {
        type: /^.*.$/,
        typeOriginal: /^[0-9]{3}-[0-9]{4}-[0-9]{4}$/,
        text: "Invalid Phone",
      },
      company: {
        type: /^.*.$/,
        text: "Empty Company",
      },
      size: {
        type: /^.*.$/,
        text: "Empty Company Size",
      },
    };
    setData((prev) => {
      if (validation[name].type.test(value)) {
        prev[name].error = false;
        prev[name].helperText = "";
      } else {
        prev[name].error = true;
        prev[name].helperText = validation[name].text;
      }
      return { ...prev };
    });
  };
  const handleOnChange = (event) => {
    const { name, value } = event.target;
    validate(name, value);
    switch (name) {
      default:
        setData((prev) => {
          prev[name].value = value;
          return { ...prev };
        });
        break;
    }
  };
  const handleStart = async () => {
    if (Object.keys(data).find((e) => data[e].error)) {
      return;
    }

    const requestData = Object.keys(data).map((e) => {
      return { [e]: data[e].value };
    });
    const { message, status } = await sendEmail(JSON.stringify(requestData));
    setAlrt((prev) => {
      prev.open = true;
      prev.message = message;
      prev.status = status !== 200 ? "warning" : "success";
      return { ...prev };
    });

    if (status === 200) {
      setData((prev) => {
        Object.keys(prev).forEach((key) => {
          prev[key].value = "";
        });
        return { ...prev };
      });
    }
  };

  const handleAlertClose = () =>
    setAlrt((prev) => {
      prev.open = !prev.open;
      prev.message = "";
      prev.status = "";
      return { ...prev };
    });

  useEffect(() => {
    setData((prev) => {
      Object.keys(prev).forEach((key) => {
        prev[key].label = t(`form.${key}`);
      });
      return { ...prev };
    });
  }, [i18n.language, t]);

  useEffect(() => {
    const navbarHeight = document.querySelector('div[class^="Navbar"]');
    setIdStyle({ top: `-${navbarHeight.clientHeight + 10}px` });
  }, []);

  return (
    <div className={container}>
      <div>
        <h1>{t("home.section1.title1")}</h1>
        <h1>{t("home.section1.title2")}</h1>
        <p>{t("home.section1.paragraph")}</p>
      </div>
      <div>
        <h1>{t("shared.try_upx")}</h1>
        <p>
          {t("home.section5.paragraph_first")}{" "}
          <span className="text-green">
            {t("home.section5.paragraph_highlighted")}
          </span>{" "}
          {t("home.section5.paragraph_last")}
        </p>
        {Object.keys(data).map((e, key) => (
          <TextField
            key={key}
            {...data[e]}
            name={e}
            onChange={handleOnChange}
          />
        ))}
        <div>
          <Button onClick={handleStart} className="round">
            {t("form.start")}
          </Button>
        </div>
        <div id="try-upx" className={identifier} style={{ ...idStyle }}></div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={alrt.open}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity={alrt.status}>
          {alrt.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default EmailForm;
