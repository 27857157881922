import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import EmailForm from "../components/Form";

import UseCaseSubStyles from "./UseCasesSub.module.scss";

import case1 from "../assets/img/case1.png";
import case2 from "../assets/img/case2.png";
import case3 from "../assets/img/case3.png";
import case4 from "../assets/img/case4.png";
import case5 from "../assets/img/case5.png";
import case6 from "../assets/img/case6.png";
import case7 from "../assets/img/case7.png";
import case8 from "../assets/img/case8.png";
import case9 from "../assets/img/case9.png";
import case10 from "../assets/img/case10.png";

import mainCase1 from "../assets/svg/business-and-finance.svg";
import mainCase2 from "../assets/svg/healthcare-life-science.svg";
import mainCase3 from "../assets/svg/marketing-advertising.svg";
import mainCase4 from "../assets/svg/e-commerce-retail.svg";
import mainCase5 from "../assets/svg/manufacturing-operations.svg";
import mainCase6 from "../assets/svg/government-public-policy.svg";
import mainCase7 from "../assets/svg/education-research.svg";
import mainCase8 from "../assets/svg/technology-it.svg";
import mainCase9 from "../assets/svg/sports-analysis.svg";
import mainCase10 from "../assets/svg/energy-utilities.svg";

const { useCaseContainer } = UseCaseSubStyles;

export const useCasesData = {
  "business-and-finance": { case: case1, main: mainCase1 },
  "healthcare-life-science": { case: case2, main: mainCase2 },
  "marketing-advertising": { case: case3, main: mainCase3 },
  "e-commerce-retail": { case: case4, main: mainCase4 },
  "manufacturing-operations": { case: case5, main: mainCase5 },
  "government-public-policy": { case: case6, main: mainCase6 },
  "education-research": { case: case7, main: mainCase7 },
  "technology-it": { case: case8, main: mainCase8 },
  "sports-analysis": { case: case9, main: mainCase9 },
  "energy-utilities": { case: case10, main: mainCase10 },
};

export default function UseCaseSub() {
  const { title } = useParams();
  const { t } = useTranslation("common");
  const handleClick = () => window.open(`/PDF/${title}.pdf`);
  return (
    <div className={useCaseContainer}>
      <div>
        <div>
          <h1>
            <span>{t(`use_cases.section1.${title}.title`)}</span>{" "}
            {t("use_cases.section1.sub")}
          </h1>
          <p>{t(`use_cases.section1.${title}.para`)}</p>
          <Button onClick={handleClick}>
            {t("use_cases.section1.download")}
          </Button>
        </div>
        <div>
          <img data-src={title} alt={title} src={useCasesData[title].main} />
        </div>
      </div>
      <div>
        <h2>{t("use_cases.section2.tech_title")}</h2>
        <div>
          <p>{t("use_cases.section2.tech_para")}</p>
          <div>
            <img alt="docker" src="/svg/used_tech1.svg" />
            <img alt="docker" src="/svg/used_tech2.svg" />
            <img alt="docker" src="/svg/used_tech3.svg" />
            <img alt="docker" src="/svg/used_tech4.svg" />
          </div>
          <div>
            <img alt="docker" src="/svg/used_tech1.svg" />
            <img alt="docker" src="/svg/used_tech2.svg" />
            <img alt="docker" src="/svg/used_tech3.svg" />
            <img alt="docker" src="/svg/used_tech4.svg" />
          </div>
        </div>
        <h1>
          {t("use_cases.section2.title")}{" "}
          <span>{t(`use_cases.section2.${title}.title`)}</span>
        </h1>
        {[1, 2, 3].map((e, key) => (
          <div key={key}>
            <h1>{t(`use_cases.section2.${title}.subtitle${e}`)}</h1>
            <ul>
              <li>{t(`use_cases.section2.${title}.subpara${e}_1`)}</li>
              <li>{t(`use_cases.section2.${title}.subpara${e}_2`)}</li>
              <li>{t(`use_cases.section2.${title}.subpara${e}_3`)}</li>
            </ul>
          </div>
        ))}
      </div>
      <div>
        <h1>{t("why_upx.startup.section2.title")}</h1>
        <p>{t("why_upx.startup.section2.para")}</p>
      </div>
      <EmailForm />
    </div>
  );
}
