import { useNavigate } from "react-router-dom";
import error500PageStyles from "./Error500.module.scss";
import { Button } from "@mui/material";

const { error500PageContainer } = error500PageStyles;
export default function Error500() {
  const navigate = useNavigate();
  const handleBack2Home = () => navigate('/');
  return (
    <div className={error500PageContainer}>
      <div>
        <p>500</p>
        <p>System Interal Error</p>
        <Button onClick={handleBack2Home}>Back to Home</Button>
      </div>
    </div>
  );
}
