import { Button, IconButton } from "@mui/material";

import { ReactComponent as Fb } from "../assets/svg/fb.svg";
import { ReactComponent as Yt } from "../assets/svg/yt.svg";
import { ReactComponent as Wb } from "../assets/svg/wb.svg";
import { ReactComponent as Tw } from "../assets/svg/twitter.svg";

import logo from "../assets/svg/logo-white.svg";

import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import styles from "./Footer.module.scss";

const { footer } = styles;

const iconButtons = [
  { element: Fb, path: "https://www.facebook.com/USP.lab" },
  { element: Yt, path: "https://www.youtube.com/@usplab" },
  { element: Tw, path: "https://twitter.com/usplab" },
  { element: Wb, path: "https://www.usp-lab.com" },
];

const Footer = () => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("#try-upx");
  };
  const handleIconButton = (link) => window.open(link, "_blank");
  return (
    <div className={footer}>
      <div>
        <img alt="logo1" src={logo} />
        <div>
          {iconButtons.map(({ path, element: Element }, key) => (
            <IconButton key={key} onClick={() => handleIconButton(path)}>
              <Element />
            </IconButton>
          ))}
        </div>
        <p>&copy; {new Date().getFullYear()} Usp Mongolia LLC</p>
      </div>
      <div>
        <p>{t("footer.services.title")}</p>
        <ul>
          <li>
            <Link to="/why-upx">{t("footer.services.item1")}</Link>
          </li>
          <li>
            <Link to="/consulting">{t("footer.services.item2")}</Link>
          </li>
          <li>
            <Link to="/use-cases">{t("footer.services.item3")}</Link>
          </li>
        </ul>
      </div>
      <div>
        <p>{t("footer.contact.title")}</p>
        <ul>
          <li>{t("footer.contact.item1")}</li>
          <li>{t("footer.contact.item2")}</li>
        </ul>
      </div>
      <div>
        <Button onClick={handleClick} className="round green">
          {t("shared.try_upx")}
        </Button>
      </div>
    </div>
  );
};

export default Footer;
