import { useState } from "react";
import ClickerComponentStyles from "./clicker.module.scss";

import circle1 from "../assets/svg/circle-1.svg"
import circle2 from "../assets/svg/circle-2.svg"
import circle3 from "../assets/svg/circle-3.svg"
import circle4 from "../assets/svg/circle-4.svg"

const { container } = ClickerComponentStyles;
export default function Clicker() {
  const [ deg, setDeg ] = useState(0);
  const circles = [
    circle1,
    circle2,
    circle3,
    circle4,
  ]
  const handleOnClick = () => setDeg(deg + 90);
  return (
    <div className={container}>
      <div
        style={{transform: `rotate(${deg}deg)`}}
      >
        {circles.map((circle,key) => (
          <div
            key={key}
            onClick={handleOnClick}
            style={{
              transform: `rotate(-${deg}deg)`,
            }}
          >
            <img alt={`circle-${key}`} src={circle} />
          </div>
        ))}
      </div>
    </div>
  );
}
