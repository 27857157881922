import { useTranslation } from "react-i18next";
import NavbarStyles from "./Navbar.module.scss";
import { menuMap } from "../lib/router";
import { NavLink, useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  // Popper, // related to language switch
  Button,
  Dialog,
} from "@mui/material";

// related to language switch
// import { ReactComponent as En } from "../assets/en_flg.svg";
// import { ReactComponent as Jp } from "../assets/jp_flg.svg";

import logo from "../assets/svg/logo.svg";

const { container, menuButton, menuButtonActive, miniMap } = NavbarStyles;

const NavbarNew = () => {
  const { t } = useTranslation("common");

  // Mini menu toggler
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // related to language switch
  // const { t, i18n } = useTranslation("common");
  // Language menu toggler
  // const [langOpen, setLangOpen] = useState(false);
  // const [anchorEl, setAnchorEl] = useState(null);
  // const handleLangPopper = (event) => {
  //   setAnchorEl(event.currentTarget);
  //   setLangOpen(prev => !prev);
  // }
  // const handleChangeLang = (event) => {
  //   setLangOpen(false);
  //   i18n.changeLanguage(event.currentTarget.name);
  // }
  // const canBeOpen = langOpen && Boolean(anchorEl);
  // const id = canBeOpen ? 'spring-popper' : undefined;

  const navigate = useNavigate();
  const handleTryUpx = () => {
    navigate("#try-upx");
  };
  const handleMiniMenuTryUpx = () => {
    setOpen(false);
    handleTryUpx();
  };
  const handleNavigation = (path) => {
    console.log("hello world");
    handleClose();
    navigate(path);
  };

  return (
    <div className={container}>
      <div>
        <NavLink to="/">
          <img alt="navbar-log" src={logo} />
        </NavLink>
      </div>
      <div>
        <ul>
          {menuMap.map(({ name, path }, key) => (
            <li key={key}>
              <NavLink to={path}>{t(name)}</NavLink>
            </li>
          ))}
        </ul>
      </div>

      <div>
        <Button onClick={handleTryUpx}>{t("shared.try_upx")}</Button>
        {/*

        2024/01/08 : Япон хэл солидог хэсгийг түр идэвхигүй болгох

        <hr/>
        <Button aria-describedby={id}  onClick={handleLangPopper}>
          {i18n.language === "en" ? <En/> : <Jp/>}
        </Button>localhost
        <Popper
          id={id}
          open={langOpen}
          anchorEl={anchorEl}
        >
          <Button name="jp" onClick={handleChangeLang}><Jp/></Button>
          <Button name="en" onClick={handleChangeLang}><En/></Button>
        </Popper> 

        */}
      </div>

      <Dialog className={miniMap} open={open} onClose={handleClose}>
        <ul>
          {menuMap.map(({ name, path }, key) => (
            <li key={key} onClick={() => handleNavigation(path)}>
              {t(name)}
            </li>
          ))}
          <Button onClick={handleMiniMenuTryUpx}>{t("shared.try_upx")}</Button>
        </ul>
      </Dialog>

      <button
        className={open ? menuButtonActive : menuButton}
        onClick={handleOpen}
      ></button>
    </div>
  );
};
export default NavbarNew;
