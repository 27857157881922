import MarqueeItemStyles from "./MarqueeItem.module.scss";

const { container } = MarqueeItemStyles;

const MarqueeItem = ({ id, source }) => {
  return (
    <div className={container}>
      <img alt={`partner${id}`} src={source} />
    </div>
  );
};
export default MarqueeItem;
