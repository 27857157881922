import React from "react";
import { createBrowserRouter } from "react-router-dom";

import Root from "../pages/Root";
import Home from "../pages/Home";
import WhyUpx from "../pages/WhyUpx";
import Consulting from "../pages/Consulting";
import UseCasesRoot from "../pages/UseCasesRoot";
import UseCases from "../pages/UseCases";
import UseCasesSub from "../pages/UseCasesSub";
import TryUpx from "../pages/TryUpx";
import Company from "../pages/Company";
import Verify from "../pages/Verify";
import Error500 from "../pages/Error500";
import Error404 from "../pages/Error404";


export const menuMap = [
  {name: "navbar.menu_items.why_upx", path: "/why-upx"},
  {name: "navbar.menu_items.consulting", path: "/consulting"},
  {name: "navbar.menu_items.use_cases", path: "/use-cases"},
  {name: "navbar.menu_items.about", path: "/about"},
];

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Root/>,
    errorElement: <Error404/>,
    children: [
      { index: true, element: <Home/> },
      { path: "/why-upx", element: <WhyUpx/> },
      { path: "/consulting", element: <Consulting/> },
      { 
        path: "/use-cases",
        element: <UseCasesRoot/>,
        children: [
          {
            index: true,
            element: <UseCases/>
          },
          {
            path: ":title",
            element: <UseCasesSub/>
          }
        ]
      },
      { path: "/try-upx", element: <TryUpx/> },
      { path: "/about", element: <Company/> },
    ],
  },
  { path: "/invitation-successfull", element: <Verify/> },
  { path: "/error", element: <Error500/> }
]);
