const url = process.env.REACT_APP_BACKEND_URL;
const apiSharedHeader = {
  "Content-Type": "text/plain",
  "ngrok-version": 2,
  "ngrok-skip-browser-warning": 69420
};

export const ping = async () => {
  const response = await fetch(`${url}/CGI/PING.CGI`, {
    method: "GET",
    headers: { ...apiSharedHeader },
  });
  const result = await response.text();
  console.log(result);
};

export const sendEmail = async (data) => {
  if (typeof data !== "string") {
    return {
      message: "User input is not valid",
      status: 404,
    };
  }
  try {
    const resp = await fetch(
      `${url}/CGI/VERIFY_USER.CGI`,
      {
        method: "POST",
        headers: { ...apiSharedHeader },
        body: data
      }
    );
    const result = await resp.text();
    console.log(resp, result);
    return {
      message: result,
      status: resp.status 
    };
  } catch(error) {
    return { 
      message: "Something went wrong!",
      status: 500
    };
  }
}
